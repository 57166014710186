import React, { useMemo, useRef } from "react";
import { APP_URL } from "../utils";
import HeroHeader from "../components/common/header/Hero";
import { Video } from "../components/common/Video";

import { StudentTestimonials } from "../components/sections/testimonials/TestimonialSections";
import { CustomGetStarted } from "../components/sections/getstarted";

import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { Script, Link } from "gatsby";
import { useLocation } from "@reach/router";
import { FERA_AI_PUBLIC_KEY } from "../services/fera-ai";
import { ProductRating } from "../components/common/header/Rating";

// The type definitions for most fields match what's in exam-landing-content.yaml
type ExamLandingContent = {
  id: string;
  name: string;
  title: string;
  explorePageTileId: string;
  explorePageCategory: string;
  getStartedLinkText: string;
  getStartedHeaderText: string;
  heroCtaCaption?: string;
  heroCtaText: string;
  heroHeaderText: React.ReactNode;
  heroLogoUrl?: string;
  heroHeaderSubtitle: React.ReactNode;
  inviteCode: string;
  trustedBy: string;
  trustedByImages: { src: ImageDataLike; alt: string }[];
  stripeLink?: string;
  // NOTE: these 4 fields below are retrieved at runtime in {ExamLandingContent.name}.js
  heroVideoMp4?: string;
  heroVideoWebm?: string;
  featureIllustrations: JSX.Element;
  testimonialData: JSX.Element;
  price?: number;
  originalPrice?: number;
  discount?: number;
  relatedExams?: { name: string; title: string; logoUrl: string }[];
};

const useRawStringToElement = (val?: unknown): JSX.Element => {
  if (!val) {
    return null;
  }

  if (typeof val !== "string") {
    return <>{val}</>;
  }

  // Trim new lines
  const children = val
    .trim()
    .split("\n")
    .map((v, idx) => {
      if (v === "") {
        return <br key={idx} />;
      }

      return <p key={idx}>{v}</p>;
    });

  return <>{children}</>;
};
// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

export const ExamLanding = ({
  content: {
    name: examId,
    title: examTitle,
    explorePageCategory,
    explorePageTileId,
    getStartedLinkText,
    getStartedHeaderText,
    heroCtaCaption,
    heroCtaText,
    heroHeaderText,
    heroLogoUrl,
    stripeLink,
    heroHeaderSubtitle,
    trustedBy,
    trustedByImages,
    heroVideoMp4,
    heroVideoWebm,
    inviteCode,
    featureIllustrations,
    testimonialData,
    price,
    originalPrice,
    discount,
    relatedExams,
  },
}: {
  content: ExamLandingContent;
}) => {
  const location = useLocation();
  const productReviewsRef = useRef<HTMLDivElement>(null);

  const examUrl = `https://zorbi.com` + location.pathname;

  const subtitle = useRawStringToElement(heroHeaderSubtitle);

  const ctaUrl = useMemo(() => {
    if (stripeLink) {
      return stripeLink;
    }

    const params = new URLSearchParams();
    const explorePageParams = new URLSearchParams();
    explorePageParams.set("category", explorePageCategory);
    explorePageParams.set("tileId", explorePageTileId);

    params.set("continue_path", `/explore?${explorePageParams.toString()}`);
    params.set("utm_source", "exam_landing");

    params.set("d", inviteCode);
    params.set("n", "Team Zorbi");
    params.set("utm_method", "cta_click");

    return APP_URL + `/invite?${params.toString()}`;
  }, [stripeLink, inviteCode, explorePageCategory, explorePageTileId]);

  return (
    <>
      <HeroHeader
        productId={examId}
        subTitle={subtitle}
        ctaText={heroCtaText}
        headerText={heroHeaderText}
        heroLogoUrl={heroLogoUrl}
        ctaCaption={heroCtaCaption}
        ctaClassName="bg-purple-600"
        ctaHref={ctaUrl}
        onRatingClick={() => productReviewsRef.current.scrollIntoView()}
        media={
          <Video
            mp4={heroVideoMp4}
            webm={heroVideoWebm}
            className="mx-auto rounded-lg shadow-md max-h-128 lg:max-h-160"
          />
        }
        price={price}
        originalPrice={originalPrice}
        discount={discount}
      />

      {relatedExams?.length ? (
        <section className="flex flex-col items-center px-4 pb-16 space-y-4 sm:px-0">
          <h1 className="text-xl sm:text-2xl">Related Exams</h1>
          <div className="flex flex-col space-y-8 sm:justify-center sm:flex-row sm:space-x-8 sm:space-y-0">
            {relatedExams.map((examData) => {
              return (
                <Link
                  key={examData.title}
                  to={`/exams/${examData.name}`}
                  className="flex flex-col items-center p-6 space-y-2 bg-white rounded-lg"
                >
                  <img
                    className="w-24 sm:w-32"
                    src={examData.logoUrl}
                    alt={examData.title}
                  />
                  <span className="text-sm text-center text-blueGray-500 sm:text-lg">
                    {examData.title}
                  </span>
                  <ProductRating productId={examData.name} />
                </Link>
              );
            })}
          </div>
        </section>
      ) : (
        <section className="pb-16">{testimonialData}</section>
      )}

      {/* <section className="px-5 py-12 text-gray-800 ">
        <div className="px-5 mx-auto md:px-12">
          <p className="w-full mb-5 text-sm font-bold text-center text-gray-400 uppercase">
            Trusted by {trustedBy} at
          </p>
          <div className="flex flex-row flex-wrap items-center justify-center w-full sm:justify-between">
            {trustedByImages.map(({ alt, src }, idx) => {
              const image = getImage(src);

              return (
                <GatsbyImage
                  alt={alt}
                  key={idx}
                  image={image}
                  draggable={false}
                  className="mx-4 my-2"
                />
              );
            })}
          </div>
        </div>
      </section> */}
      <section className="pb-16">{featureIllustrations}</section>
      <div
        ref={productReviewsRef}
        data-fera-container="573155"
        data-product_id={examId}
      />
      <CustomGetStarted
        href={ctaUrl}
        linkText={getStartedLinkText}
        headerText={getStartedHeaderText}
        onClick={() => {
          if (isBrowser) {
            window.open(ctaUrl);
          }
        }}
      />
      <Script id="fera-ai-setup">{`(function(){
  window.fera = window.fera || [];
  window.fera.push("configure", { store_pk: "${FERA_AI_PUBLIC_KEY}"} );
  window.fera.push("setProduct", { id: "${examId}", name: "${examTitle}", thumbnail_url: "${heroLogoUrl}", url: "${examUrl}" });
  var FeraCachedAsset=function(r){var n=this;this.version="0.2.3";var a=r.name||r.url.split("?")[0].split("#")[0];var o="Fera.AssetCache."+a;var s=(new Date).getTime()/1e3;var c=r.type||(r.url.indexOf(".html")!==-1?"text/template":r.url.indexOf(".css")!==-1?"text/css":"text/javascript");n.load=function(t){t=t||function(){};if(n.content){return t(n.content)}if(!e()){u(r.url,function(e){n.content=e;i(e);n.save(e);t(e)})}else{t(n.content)}return true};n.save=function(e){var t=s+(r.expiresIn||900);if(!l()||!e){return false}var n={name:a,url:r.url,expires:t,content:e};window.localStorage.setItem(o,JSON.stringify(n));return true};n.clear=function(){window.localStorage.removeItem(o);return false};var e=function(){if(!l())return false;var e=window.localStorage.getItem(o);if(!e||typeof e!=="string"){return null}var t=JSON.parse(e);if(t.expires<s||t.url!==r.url){return n.clear()}n.content=t.content;i(n.content);return true};var i=function(e){if(document.getElementById(o))return;var t=document.createElement(c==="text/css"?"style":"script");t.type=c;t.id=o;t.async=!0;t.innerHTML=e;document.body.appendChild(t)};var u=function(e,t){var n=new XMLHttpRequest;n.async=true;n.onreadystatechange=function(){if(n.readyState==4&&n.status<300){t(n.responseText)}};n.open("GET",e,true);n.send()};var l=function(){var e="test";try{window.localStorage.setItem(e,"t");window.localStorage.removeItem(e);return 1}catch(e){return 0}}}; new FeraCachedAsset({ name: 'bananastand', expiresIn: 900, url: window.feraJsUrl || "https://cdn.fera.ai/js/fera.js"}).load();
})();`}</Script>
    </>
  );
};
