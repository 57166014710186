import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import { ExamLanding } from "../../templates/ExamLanding";
import PageLayout from "../../components/common/layout/page-layout";
import {
  AWSTestimonials,
  StudentTestimonials,
} from "../../components/sections/testimonials/TestimonialSections";
import ZORBI_AWS_TESTIMONIAL_DATA from "../../components/sections/testimonials/AWSTestimonialData";

// Optimization: load the correct asset on the right route only
const useExamLandingAssets = (route) => {
  const [heroVideoMp4, setHeroVideoMp4] = useState();
  const [heroVideoWebm, setHeroVideoWebm] = useState();
  const [featureIllustrations, setFeatureIllustrations] = useState();
  const [testimonialData, setTestimonialData] = useState();

  useEffect(() => {
    (async () => {
      switch (route) {
        case "gcse": {
          const { GCSEFeatures } = await import(
            "../../components/sections/features/exams/GCSEFeatures"
          );
          const { default: GCSEHomepageMP4 } = await import(
            "../../images/product/gcse_homepage.mp4"
          );
          const { default: GCSEHomepageWEBM } = await import(
            "../../images/product/gcse_homepage.webm"
          );

          setFeatureIllustrations(<GCSEFeatures />);
          setHeroVideoMp4(GCSEHomepageMP4);
          setHeroVideoWebm(GCSEHomepageWEBM);

          setTestimonialData(<StudentTestimonials />);
          break;
        }
        case "gre": {
          const { GreFeatures } = await import(
            "../../components/sections/features/exams/GreFeatures"
          );
          const { default: LearnWordsMp4 } = await import(
            "../../images/product/learn-english.mp4"
          );
          const { default: LearnWordsWebm } = await import(
            "../../images/product/learn-english.webm"
          );

          setFeatureIllustrations(<GreFeatures />);
          setHeroVideoMp4(LearnWordsMp4);
          setHeroVideoWebm(LearnWordsWebm);

          setTestimonialData(<StudentTestimonials />);
          break;
        }
        case "cloud-practitioner": {
          const { AwsFeatures } = await import(
            "../../components/sections/features/exams/AwsFeatures"
          );

          const { default: DemoMp4 } = await import(
            "../../images/demo/aws-demo.mp4"
          );

          const { default: DemoWebm } = await import(
            "../../images/demo/aws-demo.webm"
          );

          setFeatureIllustrations(<AwsFeatures />);
          setHeroVideoMp4(DemoMp4);
          setHeroVideoWebm(DemoWebm);
          setTestimonialData(<AWSTestimonials />);
          break;
        }
        case "solutions-architect-associate": {
          const { AwsFeatures } = await import(
            "../../components/sections/features/exams/AwsFeatures"
          );

          const { default: DemoMp4 } = await import(
            "../../images/demo/aws-demo.mp4"
          );

          const { default: DemoWebm } = await import(
            "../../images/demo/aws-demo.webm"
          );

          setFeatureIllustrations(<AwsFeatures />);
          setHeroVideoMp4(DemoMp4);
          setHeroVideoWebm(DemoWebm);
          setTestimonialData(<AWSTestimonials />);
          break;
        }
        case "developer-associate": {
          const { AwsFeatures } = await import(
            "../../components/sections/features/exams/AwsFeatures"
          );

          const { default: DemoMp4 } = await import(
            "../../images/demo/aws-demo.mp4"
          );

          const { default: DemoWebm } = await import(
            "../../images/demo/aws-demo.webm"
          );

          setFeatureIllustrations(<AwsFeatures />);
          setHeroVideoMp4(DemoMp4);
          setHeroVideoWebm(DemoWebm);
          setTestimonialData(<AWSTestimonials />);
          break;
        }
        default:
          break;
      }
    })();
  }, [route]);

  return {
    heroVideoMp4,
    heroVideoWebm,
    featureIllustrations,
    testimonialData,
  };
};

const ExamLandingContent = (props) => {
  const examLandingContent = Object.entries(props.data).filter(
    ([_k, v]) => !!v
  )[0][1];

  const { name, title, description, ...content } = examLandingContent;

  const assets = useExamLandingAssets(name);

  return (
    <PageLayout title={title} description={description}>
      <ExamLanding
        content={{
          name,
          title,
          ...content,
          ...assets,
        }}
      />
    </PageLayout>
  );
};

export const query = graphql`
  fragment ExamLanding on ExamLandingContent {
    id
    name
    title
    description
    explorePageTileId
    explorePageCategory
    getStartedLinkText
    getStartedHeaderText
    heroCtaCaption
    heroCtaText
    heroHeaderText
    heroLogoUrl
    heroHeaderSubtitle
    inviteCode
    stripeLink
    trustedBy
    price
    originalPrice
    discount
    relatedExams {
      name
      title
      logoUrl
    }
  }

  # Conditional queries to set an optimal height for the logos
  query GetExamLandingContent($id: String) {
    gcse: examLandingContent(id: { eq: $id }, name: { eq: "gcse" }) {
      ...ExamLanding
      trustedByImages {
        src {
          childImageSharp {
            gatsbyImageData(height: 60, placeholder: BLURRED)
          }
        }
        alt
      }
    }

    gre: examLandingContent(id: { eq: $id }, name: { eq: "gre" }) {
      ...ExamLanding
      trustedByImages {
        src {
          childImageSharp {
            gatsbyImageData(height: 60, placeholder: BLURRED)
          }
        }
        alt
      }
    }

    aws: examLandingContent(
      id: { eq: $id }
      name: { eq: "cloud-practitioner" }
    ) {
      ...ExamLanding
      trustedByImages {
        src {
          childImageSharp {
            gatsbyImageData(height: 30, placeholder: BLURRED)
          }
        }
        alt
      }
    }

    awsSolutionsArchitect: examLandingContent(
      id: { eq: $id }
      name: { eq: "solutions-architect-associate" }
    ) {
      ...ExamLanding
      trustedByImages {
        src {
          childImageSharp {
            gatsbyImageData(height: 30, placeholder: BLURRED)
          }
        }
        alt
      }
    }

    awsDeveloperAssociate: examLandingContent(
      id: { eq: $id }
      name: { eq: "developer-associate" }
    ) {
      ...ExamLanding
      trustedByImages {
        src {
          childImageSharp {
            gatsbyImageData(height: 30, placeholder: BLURRED)
          }
        }
        alt
      }
    }
  }
`;

export default ExamLandingContent;
