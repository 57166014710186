import React from "react";

type VideoProps = {
  webm?: string;
  mp4?: string;
} & React.DetailedHTMLProps<
  React.VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>;

export const Video: React.FC<VideoProps> = ({ mp4, webm, ...props }) => {
  return (
    <video
      {...props}
      loop={props.loop ?? true}
      muted={props.muted ?? true}
      preload={props.preload ?? "auto"}
      autoPlay={props.autoPlay ?? true}
      playsInline={props.playsInline ?? true}
    >
      {webm && <source src={webm} type="video/webm" />}
      {mp4 && <source src={mp4} type="video/mp4" />}
      {props.children}
    </video>
  );
};
