import { useAsync } from "react-use";

export const FERA_AI_PUBLIC_KEY =
  "pk_ed7290e9850e25843f6500387737416f327927d8021a50349618ffb670363046";

type FeraProductAggregateRating = {
  subject: "product";
  external_product_id: string;
  product_id: string;
  average: number | null;
  count: number | null;
  ungrouped_average: number;
  ungrouped_count: number;
  verified_count: number;
};

type FeraPaginationMetadata = {
  total_count: number;
  page_size: number;
  page_count: number;
  page: number;
};

// There are many other fields, but we only need these fields for now
type FeraProductReview = { created_at: string; updated_at: string };

type ListProductReviewsResponse = {
  data: FeraProductReview[];
  meta: FeraPaginationMetadata;
};

const sendPublicFeraRequest = async <T>(
  path: string,
  init?: RequestInit
): Promise<T> => {
  return fetch("https://api.fera.ai/v3/public" + path, {
    ...init,
    headers: { ...init?.headers, "Public-Key": FERA_AI_PUBLIC_KEY },
  }).then((res) => res.json());
};

const getFeraProductRating = (productId: string) => {
  return sendPublicFeraRequest<FeraProductAggregateRating>(
    `/products/${productId}/rating`
  );
};

const listProductReviews = (productId: string) => {
  return sendPublicFeraRequest<ListProductReviewsResponse>(
    `/products/${productId}/reviews`
  );
};

export const useFeraProductRating = (productId = "") => {
  return useAsync(() => getFeraProductRating(productId), [productId]);
};

export const useFeraProductReviews = (productId = "") => {
  return useAsync(() => listProductReviews(productId), [productId]);
};
