import dayjs from "dayjs";
import React from "react";
import cx from "classnames";
import { StarIcon } from "@heroicons/react/solid";

import {
  useFeraProductRating,
  useFeraProductReviews,
} from "../../../services/fera-ai";
import { numberWithCommas } from "../../../utils";

type RatingProps = {
  value: number;
  maxValue?: number;
};

type ProductRatingProps = {
  productId: string;
  onRatingClick?: () => void;
  includeUpdatedAt?: boolean;
};

const FullStar = () => {
  return <StarIcon className="flex-shrink-0 w-5 text-yellow-300" />;
};

const NoStar = () => {
  return <StarIcon className="flex-shrink-0 w-5 text-gray-200" />;
};

const Rating: React.FC<RatingProps> = ({ value = 0, maxValue = 5 }) => {
  const fullStarCounts = Math.min(Math.floor(value), maxValue);
  const noStarCounts = maxValue - fullStarCounts;

  return (
    <div className="flex items-center">
      {Array(fullStarCounts)
        .fill(0)
        .map((_, i) => (
          <FullStar key={`full-star-${i}`} />
        ))}
      {Array(noStarCounts)
        .fill(0)
        .map((_, i) => (
          <NoStar key={`no-star-${i}`} />
        ))}
    </div>
  );
};

export const ProductRating: React.FC<ProductRatingProps> = ({
  productId,
  onRatingClick,
  includeUpdatedAt = false,
}) => {
  const productRating = useFeraProductRating(productId);
  const productReviews = useFeraProductReviews(productId);

  return productRating.value?.average ? (
    <div className="flex flex-col items-center w-full space-y-1 text-sm sm:w-auto sm:items-start">
      <div
        onClick={onRatingClick}
        className={cx(
          { "cursor-pointer": !!onRatingClick },
          "flex flex-col items-center w-full space-x-2 space-y-1 sm:flex-row"
        )}
      >
        <div className="flex flex-row items-center mt-1 space-x-1">
          <span>{productRating.value.average}</span>
          <Rating value={productRating.value.average} />
        </div>

        <span className="text-purple-500">
          (
          <span className="underline">
            {numberWithCommas(productRating.value.count)} ratings
          </span>
          )
        </span>
        {/* @note we don't have a way of storing this data yet */}
        {/* <span>188,467 students</span> */}
      </div>
      {includeUpdatedAt && productReviews.value?.data?.[0]?.updated_at ? (
        <span>
          Last updated{" "}
          {dayjs(productReviews.value.data[0].updated_at).format("MM/YYYY")}
        </span>
      ) : null}
    </div>
  ) : null;
};
