import dayjs from "dayjs";
import { useLocation } from "@reach/router";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import React, { useState } from "react";
import { ClockIcon } from "@heroicons/react/outline";

import { PRODUCT_MAP } from "../../sections/purchase_complete";
import { useLocalStorage } from "react-use";
import { ProductRating } from "./Rating";

type HeroHeaderProps = {
  productId?: string;
  src?: string;
  caption?: string;
  ctaHref: string;
  ctaText: string;
  subTitle: React.ReactNode;
  headerText: React.ReactNode;
  ctaClassName?: string;
  ctaCaption?: string;
  media?: JSX.Element;
  heroLogoUrl?: string;
  onRatingClick?: () => void;
  price?: number;
  originalPrice?: number;
  discount?: number;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

const HeroHeader = ({
  productId,
  src,
  headerText,
  heroLogoUrl,
  ctaText,
  subTitle,
  caption,
  ctaHref,
  ctaClassName,
  ctaCaption,
  media,
  onRatingClick,
  price,
  originalPrice,
  discount,
  ...props
}: HeroHeaderProps) => {
  const location = useLocation();
  const [loggedConversion, setLoggedConversion] = useState(false);
  const [salesEndTimestamp] = useLocalStorage(
    `product-${productId}-sales-end`,
    discount ? dayjs().add(5, "hours").unix() : null
  );

  const now = dayjs();
  const salesEndTime = dayjs.unix(salesEndTimestamp);

  const salesEnded = now.isAfter(salesEndTime);
  const salesTimeLeftInHours = salesEndTime.diff(now, "hours");
  const salesTimeLeftInMinutes = salesEndTime.diff(now, "minutes");

  return (
    <section
      {...props}
      className={`w-full py-20 bg-gray-50 ${props.className}`}
    >
      <div className="flex flex-col items-center px-12 mx-auto max-w-7xl lg:flex-row lg:space-x-4">
        <div className="relative z-20 flex flex-col items-start justify-center w-full h-full lg:w-3/4">
          {caption && (
            <p className="pb-5 text-sm font-semibold tracking-wide text-pink-500 uppercase">
              {caption}
            </p>
          )}

          <div className="flex flex-col items-center space-y-1 text-center sm:space-x-2 sm:flex-row pb-7 sm:text-left">
            {heroLogoUrl ? (
              <img
                className="w-32"
                src={heroLogoUrl}
                alt={headerText.toString()}
              />
            ) : null}
            <h1 className="text-2xl font-bold text-transparent xl:text-3xl bg-clip-text bg-gradient-to-r from-blue-600 via-purple-500 to-red-400">
              {headerText}
            </h1>
          </div>
          <span className="block text-lg text-center text-gray-500 pb-7 sm:text-left">
            {subTitle}
          </span>
          <ProductRating
            productId={productId}
            includeUpdatedAt={true}
            onRatingClick={onRatingClick}
          />
          <div className="flex flex-col items-center w-full space-y-6 pt-7 sm:w-auto">
            {salesEndTimestamp && !salesEnded ? (
              <div>
                <div className="flex flex-row items-center space-x-2">
                  <span className="text-2xl font-bold ">${price}</span>
                  <span className="text-gray-500 line-through">
                    ${originalPrice}
                  </span>
                  <span className="text-lg text-gray-700">{discount}% off</span>
                </div>
                <div className="flex flex-row items-center space-x-1">
                  <ClockIcon className="self-center w-5 text-red-600" />
                  {salesTimeLeftInHours > 0 ? (
                    <span className="text-red-500">
                      {salesTimeLeftInHours} hours left at this price!
                    </span>
                  ) : (
                    <span className="text-red-500">
                      {salesTimeLeftInMinutes} minutes left at this price!
                    </span>
                  )}
                </div>
              </div>
            ) : null}
            <div className="flex flex-col items-center space-y-2">
              <OutboundLink
                target="_blank"
                href={ctaHref}
                onClick={() => {
                  if (loggedConversion) {
                    return;
                  }

                  const product =
                    Object.values(PRODUCT_MAP).find(
                      (v) => v.pathname == location.pathname
                    ) ?? PRODUCT_MAP["AWS_CCP_ALL"];
                  console.log(
                    "logged begin checkout conversion",
                    product.code,
                    product.value
                  );
                  typeof window !== "undefined" &&
                    (window as any).gtag?.("event", "conversion", {
                      send_to: "AW-481086092/GY_qCKTV8uwYEIyVs-UB",
                      value: product.value,
                      currency: "USD",
                    });
                  typeof window !== "undefined" &&
                    (window as any).fbq?.("track", "InitiateCheckout", {
                      value: product.value,
                      currency: "USD",
                    });

                  setLoggedConversion(true);
                }}
                className={`px-7 sm:w-auto justify-center w-full flex items-center overflow-hidden group relative py-3 text-xl font-medium text-white bg-gray-900 rounded-full ${ctaClassName}`}
              >
                <span className="absolute inset-0 w-full h-0 transition-all duration-300 ease-out bg-pink-500 group-hover:h-full"></span>
                <span className="relative">{ctaText}</span>
              </OutboundLink>
              {ctaCaption && (
                <p className="text-xs text-gray-500">{ctaCaption}</p>
              )}
            </div>
          </div>
        </div>

        <div className="relative z-10 w-full h-full my-16 lg:my-0 lg:w-7/12">
          {media ? (
            media
          ) : (
            <img src={src} className="relative z-40 w-full h-full" />
          )}
        </div>
      </div>
    </section>
  );
};

export default HeroHeader;
